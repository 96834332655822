import axios from "axios";
import { store } from "../store/store";
import { logoutUser } from "../store/actions/userActions";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const apiInstance = axios.create();
// Add your interceptors here
apiInstance.defaults.baseURL = SERVER_URL;
apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      let currentPath = window.location.pathname;
      localStorage.setItem("currentPath", currentPath);
      localStorage.setItem(
        "sessionMessage",
        "Raven session expired please login again"
      );
      localStorage.removeItem("FBIdToken"); // <-- add your var
      store.dispatch(logoutUser());
      window.location = "/login"; // <-- add your path
    } else {
      return Promise.reject(error);
    }
  }
);