import { useEffect } from "react";
import { Redirect } from "react-router-dom";

function ClearBrowserStorage() {
  useEffect(() => {
    localStorage.clear();
    console.log("Local storage cleared");
  }, []);

  return <Redirect to="/login" />;
}

export default ClearBrowserStorage;
