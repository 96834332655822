import {
  SET_POST_CODE_AUTO_SUGGEST,
  LOADING_POST_CODE_AUTO_SUGGEST,
  POST_CODE_AUTO_SUGGEST_ERROR,
  VALIDATE_POST_CODE,
  SET_CITY,
} from "../types";

const initialState = {
  addresses: [],
  loading_address: false,
  addresses_errors: null,
  is_post_code_valid: false,
  city: "",
  country: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_POST_CODE_AUTO_SUGGEST:
      return {
        ...state,
        loading_address: true,
        addresses_errors: null,
      };
    case SET_POST_CODE_AUTO_SUGGEST:
      return {
        ...state,
        addresses: action.payload,
        loading_address: false,
        addresses_errors: null,
      };
    case POST_CODE_AUTO_SUGGEST_ERROR:
      return {
        ...state,
        loading_address: false,
        addresses_errors: action.payload,
      };
    case VALIDATE_POST_CODE:
      return {
        ...state,
        is_post_code_valid: action.payload.result,
      };
    case SET_CITY:
      return {
        ...state,
        loading_address: false,
        city: action.payload,
      };
    default:
      return state;
  }
}
